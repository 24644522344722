<template>
  <DataGridFactory
    :dataSource="store.DocListaRecebimentoXmlAguardando"
    :enabledActions="false"
    :nameToExport="'Xmls Aguardando/Canceladas'"
  >
    <template v-slot:column>
      <DxColumn
        data-field="dataRecebimento"
        data-type="date"
        caption="Data Recebimento"
      />
      <DxColumn
        data-field="cnpjCpf"
        caption="CNPJ"
        alignment="right"
        :customize-text="
          (cell) => {
            return Validators.formataCpfCnpj(cell.value);
          }
        "
      />
      <DxColumn data-field="processado" caption="Processado" />
      <DxColumn
        data-field="processamento"
        data-type="date"
        caption="Data Processamento"
      />
      <DxColumn data-field="descricao" caption="Arquivo" />
      <DxColumn data-field="chaveAguardada" caption="Chave Aguardada" />
    </template>
  </DataGridFactory>
</template>
<script src="./index.ts"></script>